<template>
    <card>
        <body-card style="margin: 0;border: none;">
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGarden.task_assign') }} {{ $t('globalTrans.details') }}</h4>
            </template>
            <template v-slot:headerAction>
                <div class="text-right">
                    <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                        <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                    </b-button>
                </div>
            </template>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <div class="p-3">
                        <b-row>
                            <b-col sm="12">
                                <b-table-simple small resonsive borderless>
                                    <b-tr>
                                        <b-th width="25%">{{ $t('teaGardenService.tea_garden_name') }}</b-th>
                                        <b-th width="2%">:</b-th>
                                        <b-td>
                                            {{ getGardenName(detailsData.garden_id) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('teaGarden.task_name') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ getTaskName(detailsData.task_id) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('teaGardenConfig.description') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ currentLocale === 'en' ? detailsData.description_en : detailsData.description_bn }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.start_date') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ detailsData.start_date | dateFormat }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('globalTrans.end_date') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ detailsData.end_date | dateFormat }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('teaGarden.working_days') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(detailsData.working_days) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="taskUnitId">
                                        <b-th>{{ $t('teaGarden.target') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(detailsData.target) }} {{ getUnitName(taskUnitId) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="taskUnitId">
                                        <b-th>{{ $t('teaGarden.total_target') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(detailsData.working_day_target) }} {{ getUnitName(taskUnitId) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="!task.is_individual">
                                        <b-th>{{ $t('teaGarden.total_achievement') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(detailsData.total_achievement) }} {{ getUnitName(taskUnitId) }}
                                        </b-td>
                                    </b-tr>
                                    <b-tr v-if="!task.is_individual">
                                        <b-th>{{ $t('teaGarden.difference') }}</b-th>
                                        <b-th>:</b-th>
                                        <b-td>
                                            {{ $n(detailsData.total_diff) }} {{ getUnitName(taskUnitId) }}
                                        </b-td>
                                    </b-tr>
                                </b-table-simple>
                                <body-card>
                                    <!-- search section start -->
                                    <template v-slot:headerTitle>
                                        <h4 class="card-title">{{ $t('teaGardenConfig.worker_list') }} {{ $t('globalTrans.list') }}</h4>
                                    </template>
                                    <template v-slot:body>
                                        <b-table-simple resonsive bordered class="mt-3 mb-0">
                                            <b-thead>
                                                <b-tr>
                                                    <b-th width="10%">{{ $t('globalTrans.sl_no') }}</b-th>
                                                    <b-th>{{ $t('teaGardenConfig.worker_name') }}</b-th>
                                                    <b-th>{{ $t('teaGardenConfig.pf_id') }}</b-th>
                                                    <b-th>{{ $t('globalTrans.designation') }}</b-th>
                                                    <b-th>{{ $t('teaGardenConfig.worker_type') }}</b-th>
                                                    <template v-if="task.is_individual">
                                                        <b-th>{{ $t('teaGarden.achievement') }}</b-th>
                                                        <b-th>{{ $t('teaGarden.difference') }}</b-th>
                                                    </template>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                                <template v-if="detailsData.workers.length">
                                                    <b-tr v-for="(item, index) in detailsData.workers" :key="index">
                                                        <b-td>{{ $n(index + 1) }}</b-td>
                                                        <b-td>{{ currentLocale === 'en' ? item.worker.worker_name_en : item.worker.worker_name_bn }}</b-td>
                                                        <b-td>{{ item.worker.pf_id | numberConvert }}</b-td>
                                                        <b-td>{{ getDesignation(item.worker.designation_id) }}</b-td>
                                                        <b-td>{{ getWorkerType(item.worker.worker_type) }}</b-td>
                                                        <template v-if="task.is_individual">
                                                            <b-td>{{ $n(item.achievement) }}</b-td>
                                                            <b-td>{{ $n(item.diff) }}</b-td>
                                                        </template>
                                                    </b-tr>
                                                </template>
                                                <template v-else>
                                                    <b-tr>
                                                        <b-td :colspan="task.is_individual ? 8 : 6" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                    </b-tr>
                                                </template>
                                            </b-tbody>
                                        </b-table-simple>
                                    </template>
                                </body-card>
                            </b-col>
                            <b-col md="12" class="text-left">
                                <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                            </b-col>
                        </b-row>
                    </div>
                </b-overlay>
            </template>
        </body-card>
    </card>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import { taskAssignShow } from '../../api/routes'
    // import Pdf from './details-pdf'
    export default {
        name: 'Details',
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                detailsData: {
                    workers: []
                },
                taskUnitId: 0,
                task: {}
            }
        },
        created () {
            this.formData()
        },
        computed: {
            id () {
                return this.$route.params.id
            },
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            gdnTaskList () {
                return this.$store.state.TeaGardenService.commonObj.gdnTaskList
            },
            designationList: function () {
                return this.$store.state.TeaGardenService.commonObj.masterDesignationList
            },
            genderList: function () {
                return this.$store.state.TeaGardenService.commonObj.gender
            },
            unitList () {
                return this.$store.state.TeaGardenService.commonObj.unitList
            }
        },
        methods: {
            back () {
                this.$router.go(-1)
            },
            async formData () {
                // this.loading = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, taskAssignShow + '/' + this.id)
                if (result.success) {
                    this.detailsData = result.data
                    const obj = this.gdnTaskList.find(item => item.value === parseInt(this.detailsData.task_id))
                    this.task = obj
                    if (obj !== undefined && obj.unit_id) {
                        this.taskUnitId = obj.unit_id
                    }
                }
                // this.loading = false
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            },
            // pdfExport () {
            //     const reportTitle = this.$t('teaGardenPanel.small_tea_garden_registration')
            //     Pdf.exportPdfDetails(teaGardenServiceBaseUrl, '/configuration/report-heading/detail', 5, reportTitle, this)
            // },
            async pdfExport () {
                const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.id })
                const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
                if (service !== undefined) {
                    if (service.office_type_id) {
                        params.office_type_id = service.office_type_id
                    }
                    if (service.office_id) {
                        params.office_id = service.office_id
                    }
                }
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
                const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, taskAssignShow + '/' + this.id, params)
                var blob = new Blob([result], {
                    type: 'application/pdf'
                })
                var url = window.URL.createObjectURL(blob)
                window.open(url).print()
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            },
            isImage (path) {
                return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
            },
            getGardenName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getTaskName (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.gdnTaskList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getWorkerType (id) {
                const obj = this.$store.state.TeaGardenService.commonObj.workerType.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getDesignation (id) {
                const obj = this.designationList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getGender (id) {
                const obj = this.genderList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getUnitName (id) {
                const obj = this.unitList.find(item => item.value === parseInt(id))
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        }
    }
</script>
<style scoped>
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #4bb543;
        color:white;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #4bb543;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
    .tagTwo {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
        background: hsl(250deg, 100%, 40%);
        padding: 5px 25px;
        font-weight: 600;
        font-size: 12px;
        color: #FFF;
        transition: clip-path 500ms;
    }
    .tagTwo:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: hsl(252deg, 100%, 60%);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 0;
        transition: transform 500ms;
    }
</style>
